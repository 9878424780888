var hostname = window.location.hostname;
console.log("hostname", hostname);

var api_base_url = false;
if (hostname === "localhost" || hostname.includes(".localhost")) {
	api_base_url = "http://api.sdm.localhost";
	//api_base_url = "https://api.dev.yellgo.cloud";
} else if (hostname.includes(".dev.")) api_base_url = "https://api.dev.yellgo.cloud";
else if (hostname.includes(".devaws.")) api_base_url = "https://api.dev.yellgo.cloud";
else if (hostname.includes(".demo.")) api_base_url = "https://api.demo.yellgo.cloud";
else api_base_url = "https://api.yellgo.cloud";
console.log("api_base_url", api_base_url);

let api_key = null;

async function oauth() {
	const response = await fetch(api_base_url + "/oauth/", {
		method: "POST",
		headers: {
			"Shared-Key": "d2",
		},
		body: JSON.stringify({
			client_id: "yellgo_selfordering",
			client_secret: "1a81a0f0a19ee75fabba4cad3e0feb58",
			host: hostname,
		}),
	});

	const json = await response.json();

	api_key = json.token;
}

async function get(endpoint) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Shared-Key": api_key,
		},
	});

	const json = await response.json();

	return json;
}

async function post(endpoint, request) {
	const response = await fetch(api_base_url + endpoint, {
		method: "POST",
		headers: {
			"Shared-Key": api_key,
		},
		body: JSON.stringify(request),
	});

	const json = await response.json();

	return json;
}

export default { oauth, get, post };
