import { useContext, useEffect } from "react";
import { CategoriesContext, SettingsContext } from "../App";
import ProductsList from "./ProductsList";
import CategoriesTags from "./CategoriesTags.js";

function ProductsOnePage() {
	const { settings, category, setCategory } = useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);

	useEffect(() => {
		/*document.getElementById("main").addEventListener("scroll", handleScroll);

		return () => {
			document.getElementById("main")?.removeEventListener("scroll", handleScroll);
		};*/
	}, [categories, category]);

	let sclollTimeOut;

	const handleScroll = (e) => {
		clearTimeout(sclollTimeOut);
		sclollTimeOut = setTimeout(() => {
			if (settings.selfordering.products_loading_type == "onepage") {
				console.log("handleScroll");
				let visibleCategory = false;
				for (const categoryBox of document.getElementsByClassName("category-box")) {
					const rect = categoryBox.getBoundingClientRect();
					if (rect.top < getContainerBottom() && rect.bottom > getContainerTop()) {
						visibleCategory = categoryBox;
						break;
					}
				}
				console.log(visibleCategory.id);
				if (visibleCategory) {
					console.log("change category by scroll to", visibleCategory);
					for (const course of categories) {
						for (const category of course.categories) {
							if ("category-" + category.id == visibleCategory.id) {
								setCategory(category);
							}
						}
					}
				}
			}
		}, 100);
	};

	const getContainerTop = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.top;
		}
	};

	const getContainerBottom = () => {
		const container = document.getElementById("main");
		if (container) {
			const rect = container.getBoundingClientRect();
			return rect.bottom;
		}
	};

	return (
		<>
			{categories.map((course, i) => (
				<div key={i}>
					{course.name && <h3 className="mb-3">{course.name}</h3>}
					{course.categories.map((category, n) => (
						<div key={n} id={"category-" + category.id} className="category-box">
							<div className="category-title-container">
								<h3 className="category-title">{category.name}</h3>
								<CategoriesTags category={category} />
							</div>
							{category.description != "" && (
								<p className="mb-3">{category.description}</p>
							)}
							<ProductsList products={category.products} />
						</div>
					))}
				</div>
			))}
		</>
	);
}

export default ProductsOnePage;
