import { useContext } from "react";
import { SettingsContext } from "../App";
import "../css/QtySelector.css";

function CartQtySelector({ value, onChange, ifEmpty = false }) {
	const { settings } = useContext(SettingsContext);
	const increment = () => {
		if (value > 0) onChange(value + 1);
		else ifEmpty();
	};

	const decrement = () => {
		if (value > 0) onChange(value > 0 ? value - 1 : 0);
	};

	return (
		<div className="qty-selector">
			<button onClick={decrement} className="minus">
				<div>-</div>
			</button>
			<div className="qty">{value}</div>
			<button onClick={increment} className="plus">
				<div>+</div>
			</button>
		</div>
	);
}

export default CartQtySelector;
